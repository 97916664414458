import React from "react";
import { UploadOutlined, ShopOutlined } from "@ant-design/icons";
import MapIcon from "@mui/icons-material/Map";
import { Badge, Button, Menu, theme, Tooltip, Typography } from "antd";
import Sider from "antd/es/layout/Sider";
import { observer } from "mobx-react-lite";
import mainStore from "../stores/main";

const SiderMenu = observer(({ collapsed, setCurrentPage }) => {
  const systemStore = mainStore.system;
  const webSocketStore = mainStore.ws;
  const antTheme = theme.useToken();

  return systemStore.isDesktop ? (
    <Sider
      style={{
        backgroundColor: systemStore.darkMode
          ? antTheme.token.colorBgBlur
          : antTheme.token.colorBgBase,
      }}
      trigger={null}
      collapsible
      collapsed={collapsed}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: 30,
          textAlign: "center",
          fontWeight: "bold",
          padding: 5,
          cursor: "default",
        }}
      >
        <Tooltip
          color={
            webSocketStore.status === "connected"
              ? "green"
              : webSocketStore.status === "connected"
              ? "orange"
              : "red"
          }
          title={`WebSocket Status: ${webSocketStore.status}`}
        >
          <Badge
            status={
              webSocketStore.status === "connected"
                ? "success"
                : webSocketStore.status === "connected"
                ? "warning"
                : "error"
            }
            dot
          >
            <Typography
              style={{
                padding: 0,
              }}
            >
              BS WEBAPP
            </Typography>
          </Badge>
        </Tooltip>
      </div>
      <Menu
        mode="inline"
        theme={systemStore.darkMode ? "dark" : "light"}
        style={{
          background: systemStore.darkMode
            ? antTheme.token.colorBgBlur
            : antTheme.token.colorBgBase,
        }}
        defaultSelectedKeys={"shops"}
        onClick={(e) => {
          setCurrentPage(e.key);
        }}
        items={[
          {
            key: "shops",
            icon: <ShopOutlined />,
            label: "Работа с магазинами",
          },
          {
            key: "jobs",
            icon: <MapIcon />,
            label: "Работа с заявками",
          },
          {
            key: "maps",
            icon: <UploadOutlined />,
            label: "Карта магазинов",
          },
        ]}
      />
      <Button
        onClick={() => {
          webSocketStore.ws.send(JSON.stringify({ type: "getClients" }));
        }}
      >
        Test
      </Button>
    </Sider>
  ) : (
    ""
  );
});

export default SiderMenu;
