import { autorun, makeAutoObservable } from "mobx";
import { theme } from "antd";

class ObservableSystemStore {
  isDesktop = null;
  showDebug = true;

  theme = {
    // algorithm: theme.compactAlgorithm,
    token: {
      colorPrimary: "green",
    },
  };
  darkMode = false;
  messageCount = 0;
  constructor(rootStore) {
    this.rootStore = rootStore;
    try {
      this.theme = JSON.parse(localStorage.getItem("theme"));
      this.darkMode = JSON.parse(localStorage.getItem("darkMode"));
      this.updateTheme();
    } catch (error) {}
    makeAutoObservable(this);

    autorun(() => {
      localStorage.setItem("theme", JSON.stringify(this.theme));
      localStorage.setItem("darkMode", JSON.stringify(this.darkMode));
    });
  }
  setIsDesktop(isDesktop) {
    this.isDesktop = isDesktop;
  }

  setMessageHandler(message) {
    this.message = message;
  }
  setAuthNotifyHandler(message) {
    this.authNotify = message;
  }
  setDataNotifyHandler(message) {
    this.dataNotify = message;
  }
  setTheme(theme) {
    this.theme = theme;
  }
  setDarkMode(darkMode) {
    this.darkMode = darkMode;
    this.updateTheme();
  }
  updateTheme() {
    if (this.darkMode) {
      this.theme = {
        algorithm: this.darkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
        token: {
          colorPrimary: "black",
          // colorPrimaryBg: `white`,
          // colorBgContainer: "white",
          // colorBgLayout: "white",
        },
      };
    } else {
      this.theme = {
        algorithm: this.darkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
        token: {
          // colorPrimaryBg: `white`,
          colorPrimary: "blue",
          // colorBgContainer: "white",
          // colorBgLayout: "white",
        },
      };
    }
  }
  addMessageToCount() {
    return this.messageCount++;
  }
}

export default ObservableSystemStore;
