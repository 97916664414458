import ObservableAuthStore from "./auth";
import ObservableDataStore from "./data";
import ObservableSystemStore from "./system";
import ObservableWebSocketStore from "./ws";

class MainStore {
  constructor() {
    this.system = new ObservableSystemStore(this);
    this.data = new ObservableDataStore(this);
    this.auth = new ObservableAuthStore(this);
    this.ws = new ObservableWebSocketStore(this);
  }
}

const mainStore = new MainStore();
export default mainStore;
