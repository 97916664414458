import { Content } from "antd/es/layout/layout";
import React, { useEffect, useRef } from "react";

const AllShopMap = () => {
  const mapRef = useRef(null);
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.charset = "utf-8";
    script.async = true;
    script.src =
      "https://api-maps.yandex.ru/services/constructor/1.0/js/?um=constructor%3Ab28eb1095dfeaef3f97bc091448fb5927632b8f4057aa3e481de4e292b1780f4&width=100%25&height=720&lang=en_FR&scroll=true";
    if (mapRef.current) {
      mapRef.current.appendChild(script);
    }

    return () => {
      mapRef.current = null;
    };
  }, []);

  return (
    <Content
      style={{
        margin: "24px 16px",
        padding: 24,
        minHeight: 280,
      }}
    >
      <div ref={mapRef} style={{ width: "100%", height: "720px" }}></div>
    </Content>
  );
};

export default AllShopMap;
