import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import App from "../App";
import mainStore from "../stores/main";
import AuthFromTelegram from "./authFromTelegram";
import Cookies from "js-cookie";

const Auth = observer(() => {
  const authStore = mainStore.auth;
  const wsStorage = mainStore.ws;
  const tg = window?.Telegram?.WebApp;

  useEffect(() => {
    if (wsStorage.status === "disconnect" && authStore.userId) {
      wsStorage.connectWs();
      console.log(authStore.userId);
    }
  }, [wsStorage.status, authStore.userId]);

  useEffect(() => {
    if (tg.initDataUnsafe.user) {
      console.log(tg.initDataUnsafe);
      const userId = tg.initDataUnsafe.user.id;
      if (userId) {
        authStore.setUserId(userId);
      }
    } else if (process.env.NODE_ENV == "development") {
      authStore.setUserId("761121054");
    }
    if (tg.initDataUnsafe.user) {
      tg.expand();
    }
    return () => {};
  }, [tg.initDataUnsafe]);

  return !authStore.userId ? <AuthFromTelegram /> : <App />;
});

export default Auth;
