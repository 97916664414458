import axios from "axios";
import { autorun, makeAutoObservable } from "mobx";
import CryptoJS from "crypto-js";

class ObservableAuthStore {
  auth = { success: false };
  userId = null;
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
    this.auth = JSON.parse(localStorage.getItem("auth"));
    this.userId = JSON.parse(localStorage.getItem("userId"));
    autorun(() => {
      console.log(this);
      localStorage.setItem("auth", JSON.stringify(this.auth));
      localStorage.setItem("userId", JSON.stringify(this.userId));
    });
  }

  setAuth(auth) {
    this.auth = auth;
  }
  async checkAuth() {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_SERVER}/auth/check`,
        {
          withCredentials: true,
        }
      );
      if (res.data.success) {
        this.setAuth({ ...res.data });
      } else {
        this.setAuth({ success: false });
      }
    } catch (error) {
      console.log(error);
      this.setAuth({ success: false });
    }
  }
  setUserId(userId) {
    this.userId = userId;
  }
}

// const authStore = new ObservableAuthStore();
export default ObservableAuthStore;
