import React from "react";
import { App, ConfigProvider, message, notification } from "antd";
import { YMaps } from "@pbe/react-yandex-maps";
import { observer } from "mobx-react-lite";
import { BrowserRouter } from "react-router-dom";
import mainStore from "../stores/main";

const Provider = observer((props) => {
  const systemStore = mainStore.system;
  const [msg, contentWsMessage] = message.useMessage({
    duration: 3,
    maxCount: 3,
    prefixCls: "ws",
  });
  const [authNotify, contentAuthNotify] = notification.useNotification({
    placement: "bottomRight",
    bottom: 50,
    duration: 3,
    showProgress: true,
  });
  const [dataNotify, contentDataNotify] = notification.useNotification({
    placement: "bottomLeft",
    bottom: 50,
    duration: 3,
    showProgress: true,
  });
  systemStore.setMessageHandler(msg);
  systemStore.setAuthNotifyHandler(authNotify);
  systemStore.setDataNotifyHandler(dataNotify);

  return (
    <ConfigProvider theme={systemStore.theme}>
      <BrowserRouter>
        <YMaps>
          {contentWsMessage}
          <div key="auth-notification-holder">{contentAuthNotify}</div>
          <div key="data-notification-holder">{contentDataNotify}</div>
          <App>{props.children}</App>
        </YMaps>
      </BrowserRouter>
    </ConfigProvider>
  );
});

export default Provider;
