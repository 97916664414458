import React from "react";
import ReactDOM from "react-dom/client";
import axios from "axios";
import Provider from "./components/Provider";
import 'antd/dist/reset.css';
import Auth from "./components/Auth";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider>
    <Auth />
  </Provider>
);

axios.defaults.withCredentials = true;
