import { LoginButton } from "@telegram-auth/react";
import React from "react";
import mainStore from "../stores/main";

const AuthFromTelegram = () => {
  const authStore = mainStore.auth;
  return (
    <LoginButton
      botUsername={"bs_web_bot"}
        onAuthCallback={(data) => {
          console.log(data);
          console.log("AUTH FROM BTN SUCCESS");
          authStore.setUserId(data.id);
        }}
    //   authCallbackUrl={`${process.env.REACT_APP_BACKEND_SERVER}/auth`}
      buttonSize="medium" // "large" | "medium" | "small"
      cornerRadius={5} // 0 - 20
      showAvatar={false} // true | false
      lang="ru"
    />
  );
};

export default AuthFromTelegram;
