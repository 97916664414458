import { autorun, makeAutoObservable } from "mobx";
import Cookies from "js-cookie";

class ObservableWebSocketStore {
  ws = null;
  connectionCount = 0;
  status = "disconnect";
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
    autorun(() => {});
  }

  setWs(ws) {
    this.ws = ws;
  }
  connectWs() {
    if (this.status == "disconnect" && this.connectionCount == 0) {
      try {
        const websocket = new WebSocket(`${process.env.REACT_APP_WS_SERVER}`);
        this.addHandlersForWs(websocket);
        this.setWs(websocket);
        this.status = "connecting";
        this.rootStore.system.message.info(
          `Устанавливается подключение к серверу...`
        );
        this.connectionCount++;
      } catch (error) {
        console.log(error);
        this.status = "error";
      }
    } else if (
      this.rootStore.auth?.auth?.success &&
      this.status == "disconnect" &&
      this.connectionCount > 0
    ) {
      setTimeout(() => {
        try {
          const websocket = new WebSocket(`${process.env.REACT_APP_WS_SERVER}`);
          this.addHandlersForWs(websocket);
          this.setWs(websocket);
          this.status = "connecting";
          this.rootStore.system.message.info(
            `Устанавливается подключение к серверу...`
          );
          this.connectionCount++;
        } catch (error) {
          console.log(error);
          this.status = "error";
        }
      }, 30000);
    }

    this.connectionCount++;
  }
  addHandlersForWs(ws) {
    if (ws) {
      ws.onopen = () => {
        this.status = "connected";
        this.rootStore.system.message.success(
          `Подключение успешно установлено...`
        );
        const userId = this.rootStore.auth.userId;
        console.log(userId);
        if (userId) {
          ws.send(
            JSON.stringify({
              type: "auth",
              // signature: signature,
              userId: this.rootStore.auth.userId,
            })
          );
        } else {
          this.rootStore.system.message.error(`Ошибка авторизации...`);
        }
      };

      ws.onclose = () => {
        this.status = "disconnect";
        this.rootStore.system.message.error(`Связь с сервером прервана...`);
        ws = null;
        console.log("WebSocket connection closed");
      };

      ws.onerror = (error) => {
        this.status = "error";
        console.error("WebSocket error:", error);
      };

      ws.onmessage = this.messageHandler;
      return ws;
    }
  }
  messageHandler = (event) => {
    const msg = JSON.parse(event.data);
    switch (msg?.type) {
      //Пришли куки
      case "cookie":
        console.log(msg.data);
        if (msg.data.cookie.token) {
          Cookies.set("token", msg.data.cookie.token, { expires: 365 });
        }
      //Пришло что то об авторизации
      case "auth":
        if (msg.error) {
          this.rootStore.system.authNotify.error({
            message: "Ошибка авторизации",
            description: msg.error,
          });
          this.rootStore.auth.setAuth({
            success: false,
            error: msg.error,
          });
        }
        if (msg.message) {
          this.rootStore.system.authNotify.info({
            message: "Сообщение авторизации",
            description: msg.message,
          });
        }
        if (msg.data.user) {
          this.rootStore.auth.setAuth({
            success: true,
            user: msg.data.user,
          });
        }
        break;
      case "currentClient":
        console.log(msg.data);
        break;
      //Пришел ответ на запрос
      case "response":
        this.rootStore.data.updateData(msg.request.req_type, msg.data);
        break;
      default:
        break;
    }
    if (msg.error) {
      console.error("Error:", msg.error);
    } else {
      // console.log("Message from WS:", msg);
    }
  };
}

export default ObservableWebSocketStore;
