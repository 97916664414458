import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import MainLayout from "./pages/MainLayout";
import { useMediaQuery } from "react-responsive";
import mainStore from "./stores/main";
import "./App.css";

const App = observer(() => {
  const systemStore = mainStore.system;
  const isDesktop = useMediaQuery({ query: "(min-width: 769px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  useEffect(() => {
    if (isDesktop) {
      systemStore.setIsDesktop(true);
    } else if (isMobile) {
      systemStore.setIsDesktop(false);
    }
  }, [isDesktop, isMobile]);

  return (
    <>
      <MainLayout />
    </>
  );
});

export default App;
