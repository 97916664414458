import React from "react";
import ThemeSwitcher from "../components/themeSwitcher";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Button, theme, Layout, Tooltip, Badge, Typography } from "antd";
import { observer } from "mobx-react-lite";
import mainStore from "../stores/main";

const { Header: HeaderLayout } = Layout;
const Header = observer(({ collapsed, setCollapsed }) => {
  const systemStore = mainStore.system;
  const webSocketStore = mainStore.ws;
  const antTheme = theme.useToken();

  return (
    <HeaderLayout
      style={{
        padding: 0,
        margin: 0,
        background: systemStore.darkMode
          ? antTheme.token.colorBgBlur
          : antTheme.token.colorBgBase,
        display: "flex",
        alignItems: "center",
      }}
    >
      {systemStore.isDesktop ? (
        <Button
          type="text"
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={() => setCollapsed(!collapsed)}
          style={{
            fontSize: "16px",
            width: 64,
            height: 64,
          }}
        />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: 30,
            textAlign: "center",
            fontWeight: "bold",
            padding: 5,
            cursor: "default",
          }}
        >
          <Tooltip
            color={
              webSocketStore.status === "connected"
                ? "green"
                : webSocketStore.status === "connected"
                ? "orange"
                : "red"
            }
            title={`WebSocket Status: ${webSocketStore.status}`}
          >
            <Badge
              status={
                webSocketStore.status === "connected"
                  ? "success"
                  : webSocketStore.status === "connected"
                  ? "warning"
                  : "error"
              }
              dot
            >
              <Typography
                style={{
                  fontSize: "20px",
                  padding: 0,
                  marginLeft: 5,
                }}
              >
                BS WEBAPP
              </Typography>
            </Badge>
          </Tooltip>
        </div>
      )}
      <div style={{ marginLeft: "auto", marginRight: 10 }}>
        <ThemeSwitcher />
      </div>
    </HeaderLayout>
  );
});

export default Header;
