import { Content } from "antd/es/layout/layout";
import React from "react";
import { observer } from "mobx-react-lite";
import AllShopMap from "../components/AllShopMap";
import ShopComponents from "../components/ShopComponents";

const ContentPage = observer(({ type }) => {
  switch (type) {
    case "shops":
      return <ShopComponents />;
    case "jobs":
      return (
        <Content
          style={{
            margin: "0 16px",
            padding: 24,
            minHeight: 280,
          }}
        >
          {type}
        </Content>
      );
    case "maps":
      return <AllShopMap />;

    default:
      break;
  }
});

export default ContentPage;
