import React, { useState } from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Button, Layout, theme } from "antd";
import { observer } from "mobx-react-lite";
import SiderMenu from "../components/SiderMenu";
import ContentPage from "./ContentPage";
import Header from "../components/Header";

const MainLayout = observer(() => {
  const [collapsed, setCollapsed] = useState(false);
  const [currentPage, setCurrentPage] = useState("shops");
  return (
    <Layout style={{ minHeight: "calc(100vh - 34px)" }}>
      <SiderMenu setCurrentPage={setCurrentPage} collapsed={collapsed} />
      <Layout style={{ margin: 0 }}>
        <Header collapsed={collapsed} setCollapsed={setCollapsed} />
        <ContentPage type={currentPage} />
      </Layout>
    </Layout>
  );
});
export default MainLayout;
