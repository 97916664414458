import { autorun, makeAutoObservable } from "mobx";

class ObservableDataStore {
  storage = {
    market_data: {},
  };
  darkMode = false;
  constructor(rootStore) {
    this.rootStore = rootStore;
    // this.storage = JSON.parse(localStorage.getItem("storage"));
    makeAutoObservable(this);

    autorun(() => {
      // if (this.storage) {
      //   localStorage.setItem("storage", JSON.stringify(this.storage));
      // }
      if (this.darkMode) {
        localStorage.setItem("darkMode", JSON.stringify(this.darkMode));
      }
    });
  }
  updateData(type, data) {
    if (!this.storage) {
      this.storage = {};
    }
    if (this.rootStore.system.showDebug && this.storage.market_data?.loading) {
      this.rootStore.system.dataNotify.info({
        message: "Получены новые данные",
        description: `Тип: ${type} ${
          data?.number && ` | Номер магазина: ${data.number}`
        }`,
      });
    }
    this.storage[type] = { ...data, loading: false };
  }
  getData(type, data) {
    console.log(type, data);
    const sendData = JSON.stringify({
      type: "request",
      req_type: type,
      data,
    });
    this.rootStore.ws.ws.send(sendData);
    this.storage[type] = { ...this.storage[type], loading: true };
  }
  switchEthernetPC(ip) {
    const sendData = JSON.stringify({
      type: "ethernet_pc",
      data: { ip_mkr: ip.replace("10", "254"), ip_pc: ip },
    });
    this.rootStore.ws.ws.send(sendData);
  }
}

export default ObservableDataStore;
