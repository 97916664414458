import React from "react";
import { Layout, Switch } from "antd";
import { theme } from "antd";
import { observer } from "mobx-react-lite";
import { MoonOutlined, SunOutlined } from "@ant-design/icons";
import mainStore from "../stores/main";

const { Content } = Layout;

const ThemeSwitcher = observer(() => {
  const systemStore = mainStore.system;
  const antTheme = theme.useToken();
  return (
    <Switch
      style={{
        marginRight: 10,
        background: systemStore.darkMode ? antTheme.colorBgContainer : "green",
      }}
      checkedChildren={<SunOutlined style={{ color: "white" }} />}
      unCheckedChildren={<MoonOutlined style={{ color: "black" }} />}
      // defaultChecked
      value={!systemStore.darkMode}
      onChange={(checked) => {
        systemStore.setDarkMode(!checked);
      }}
    />
  );
});

export default ThemeSwitcher;
