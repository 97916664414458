import React from "react";
import {
  BorderOuterOutlined,
  DesktopOutlined,
  DotNetOutlined,
  EllipsisOutlined,
  ScanOutlined,
  WifiOutlined,
} from "@ant-design/icons";
import { Avatar, Badge, Card, Divider, Typography } from "antd";
import { Map, Placemark } from "@pbe/react-yandex-maps";
import mainStore from "../stores/main";

const { Meta } = Card;
const StatusCard = ({ marketData, host, type }) => {
  const systemStore = mainStore.system;
  const dataStore = mainStore.data;
  let imageCard;
  let iconCard;
  let titleCard;
  let actionsCard;
  switch (type) {
    case "device":
      switch (host.host) {
        case "C101":
          imageCard =
            "https://cdn.posbank.com/uploads/product/apexa-gw1560.png";
          iconCard = (
            <ScanOutlined style={{ marginRight: 5, fontSize: "20px" }} />
          );
          titleCard = "Касса 1";
          break;
        case "C102":
          imageCard =
            "https://cdn.posbank.com/uploads/product/apexa-gw1560.png";
          iconCard = (
            <ScanOutlined style={{ marginRight: 5, fontSize: "20px" }} />
          );
          titleCard = "Касса 2";
          break;
        case "C103":
          imageCard =
            "https://cdn.posbank.com/uploads/product/apexa-gw1560.png";
          iconCard = (
            <ScanOutlined style={{ marginRight: 5, fontSize: "20px" }} />
          );
          titleCard = "Касса 3";
          break;
        case "R":
          imageCard =
            "https://nanocom.uz/wp-content/uploads/2022/07/file565.png";
          iconCard = (
            <BorderOuterOutlined
              size={"big"}
              style={{ marginRight: 5, fontSize: "20px" }}
            />
          );
          titleCard = "Mikrotik";
          break;
        case "A10":
          imageCard =
            "https://images.satu.kz/106365656_w600_h600_106365656.jpg";
          iconCard = (
            <DesktopOutlined
              size={"big"}
              style={{ marginRight: 5, fontSize: "20px" }}
            />
          );
          titleCard = "Компьютер";
          actionsCard = [
            <DotNetOutlined
              onClick={() => {
                try {
                  console.log("ether_pc");
                  dataStore.switchEthernetPC(host.ip);
                } catch (error) {
                  console.log(error);
                }
              }}
            />,
          ];
          break;
        case "T60":
          imageCard =
            "https://images.prom.ua/1064517131_w300_h300_vesy-digi-sm.jpg";
          iconCard = (
            <BorderOuterOutlined
              size={"big"}
              style={{ marginRight: 5, fontSize: "20px" }}
            />
          );
          titleCard = "Весы DIGI";
          break;
        case "U160":
          imageCard =
            "https://cdn.connectec.uk/uploads/products/U6-LR.png?scale.height=400&canvas.height=400&scale.width=500&canvas.width=500&canvas.opacity=0";
          iconCard = (
            <WifiOutlined
              size={"big"}
              style={{ marginRight: 5, fontSize: "20px" }}
            />
          );
          titleCard = "Unifi";
          break;

        default:
          break;
      }
      return (
        <Card
          id={titleCard}
          bordered={true}
          hoverable={true}
          style={{
            minHeight: !systemStore.isDesktop ? "100%" : "230px",
            fontSize: 12,
          }} // 100% ширина на мобильных устройствах
          cover={
            <img
              style={{
                marginTop: 5,
                height: 200,
                objectFit: "cover",
              }}
              alt="example"
              src={imageCard}
            />
          }
          actions={
            actionsCard ? actionsCard : [<EllipsisOutlined key="ellipsis" />]
          }
        >
          <>
            <Badge
              dot
              status={host.alive ? "success" : "error"}
              text={
                <>
                  <b style={{ color: host.alive ? "green" : "red" }}>
                    {titleCard}
                  </b>
                  <b style={{ fontSize: 10 }}>
                    {host.alive && ` (${host.min}/${host.max})`}
                  </b>
                </>
              }
            />
            <Typography style={{ fontSize: 14 }}>
              Адрес: <b>{host.ip}</b>
            </Typography>
          </>
        </Card>
      );

    case "marketInfo":
      return (
        <Card id={titleCard}>
          <div style={{ justifyContent: "center" }}>
            <Map
              style={{
                width: "100%",
                height: systemStore.isDesktop ? "50vh" : "30vh",
              }}
              defaultState={{
                center: [marketData.latitude, marketData.longitude],
                zoom: 14,
              }}
            >
              <Placemark
                defaultGeometry={[marketData.latitude, marketData.longitude]}
              />
            </Map>
          </div>
          <Divider />
          <Meta
            avatar={
              <Avatar
                style={{
                  backgroundColor: "#091328",
                }}
              />
            }
            title={`${marketData.name} | ${marketData.loc}`}
            description={
              <>
                <Typography>Номер магазина: {marketData.number}</Typography>
                <Typography>Кол-во касс: 2?</Typography>
                <Typography>Адрес: {marketData.addr}</Typography>
                <Typography>
                  Супервайзер: {marketData.supervisor.name}
                </Typography>
              </>
            }
          />
        </Card>
      );
    default:
      break;
  }
};
export default StatusCard;
