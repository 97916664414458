import React, { useEffect, useState } from "react";
import { Button, Col, Row, Spin, theme, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import StatusCard from "../components/StatusCard";
import Search from "antd/es/input/Search";
import {
  ArrowLeftOutlined,
  LoadingOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import mainStore from "../stores/main";

const ShopComponents = observer(() => {
  const dataStore = mainStore.data;
  const systemStore = mainStore.system;
  const currentShop = dataStore.storage
    ? dataStore?.storage["market_data"]
    : {};
  const themeAnt = theme.useToken();
  const [shopNumber, setShopNumber] = useState("");

  const onSearch = (value, _e, info) => {
    if (shopNumber.length > 0) {
      dataStore.getData("market_data", { number: shopNumber.padStart(3, 0) });
    } else if (
      dataStore.storage !== null &&
      dataStore.storage["market_data"]?.number > 0
    ) {
      dataStore.getData("market_data", {
        number: dataStore.storage["market_data"].number,
      });
    } else {
      dataStore.updateData("market_data", {});
    }
  };

  useEffect(() => {
    onSearch();
    return () => {};
  }, []);

  if (currentShop?.id) {
    return (
      <Spin
        indicator={
          <LoadingOutlined style={{ fontSize: 100, marginTop: 50 }} spin />
        }
        spinning={currentShop.loading}
      >
        <Content>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "10px",
              padding: 10,
            }}
          >
            <Button
              type="primary"
              shape="round"
              icon={<ArrowLeftOutlined />}
              size={"large"}
              disabled={currentShop.loading}
              style={{ marginRight: "auto" }}
              onClick={(e) => {
                e.preventDefault();
                dataStore.updateData("market_data", {});
              }}
            />
            <div style={{ display: "flex", gap: 10, marginRight: "auto" }}>
              <Typography.Title
                style={{
                  textAlign: "center",
                  marginRight: "auto",
                  fontSize: systemStore.isDesktop ? "35px" : "23px",
                }}
              >
                Информация о магазине {currentShop.name}
              </Typography.Title>
              <Button
                type="primary"
                shape="round"
                icon={<SyncOutlined spin={currentShop.loading} />}
                size={"large"}
                disabled={currentShop.loading}
                style={{ marginRight: "auto" }}
                onClick={onSearch}
              />
            </div>
          </div>
          <Row style={{ marginBottom: 20 }} justify="space-around">
            <Col style={{ marginBottom: 20 }} lg={11} xs={24}>
              <StatusCard type="marketInfo" marketData={currentShop} />
            </Col>
            <Col lg={12} xs={24}>
              <Row justify="space-around" gutter={[16, 16]}>
                {currentShop.pings.map((host) => (
                  <Col key={host.ip} lg={8} xs={24}>
                    <StatusCard type="device" host={host} />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Content>
      </Spin>
    );
  } else {
    return (
      <Content
        style={{
          margin: "24px 16px",
          minHeight: 280,
          background: themeAnt.token.colorPrimaryBg,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 10,
          padding: 50,
        }}
      >
        <Typography.Title
          style={{
            textAlign: "center",
            fontSize: systemStore.isDesktop ? "35px" : "23px",
          }}
        >
          Введите номер магазина:
        </Typography.Title>
        <Search
          type="number"
          placeholder="Введите номер магазина"
          enterButton="Искать"
          size="large"
          loading={currentShop?.loading}
          style={{ maxWidth: "300px" }}
          onSearch={onSearch}
          onChange={(e) => {
            setShopNumber(e.target.value);
          }}
          value={shopNumber}
          disabled={currentShop == "loading" ? true : false}
        />
      </Content>
    );
  }
});

export default ShopComponents;
